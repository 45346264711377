import React from 'react';
import { useEffect, useState, useRef } from 'react';
import alertSuccess from 'src/images/alert-success.svg';
// import './styles.scss';
/**
 * Wrapper of reactstrap button that defaults to our standard primary outline.
 *
 * Additional functionality:
 * loading: true | false (displays a loading spinner to left of text and disables button)
 */
const ThemedButton = ({ loading = false, children, onSuccess, ...props }) => {
  const [successState, setSuccessState] = useState(false);
  // const successIcon = useRef(null);
  useEffect(() => {
    if (onSuccess) {
      setSuccessState(true);

      setTimeout(() => {
        setSuccessState(false);
      }, 3000);
    }
  }, [onSuccess]);

  return (
    <button disabled={loading || successState} {...props}>
      <img
        hidden={!successState}
        // style={{ display: successState ? '' : 'none' }}
        // ref={successIcon}
        // className={'success-icon'}
        src={alertSuccess}
      />
      <div
        className="spinner-border"
        style={{ display: loading ? '' : 'none' }}
      ></div>{' '}
      {!loading && !successState && children}
    </button>
  );
};

ThemedButton.defaultProps = {
  // outline: true,
  color: 'primary',
};

export default ThemedButton;
