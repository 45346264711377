import React from 'react';
import { Location } from '@reach/router';
function withLocation(WrappedComponent) {
  return props => (
    <Location>
      {location => <WrappedComponent location={location.location} {...props} />}
    </Location>
  );
}

export default withLocation;
