import React, { Component } from 'react';
import Query from '../components/GraphQL/components/Query';
import gql from 'graphql-tag';
import LoadingSpinner from 'src/components/LoadingSpinner';

const UserContext = React.createContext();

const USER_QUERY = gql`
  query {
    me {
      ipAddress
      userName
      role
      firstName
    }
  }
`;

const UserProvider = ({ children }) => {
  return (
    <Query query={USER_QUERY}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div className="container pt-3">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            </div>
          );
        if (error) return <p className="mt-2">{error.message}</p>;
        return (
          <UserContext.Provider
            value={{
              state: {
                userData: data.me,
              },
            }}
          >
            {children}
          </UserContext.Provider>
        );
      }}
    </Query>
  );
};

const UserConsumer = UserContext.Consumer;

export default UserProvider;
export { UserConsumer, UserContext };
