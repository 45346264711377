import React from 'react';
import './styles.scss';

const LoadingSpinner = ({ customDimensions, lightenColor }) => {
  return (
    <div
      className={`${lightenColor ? 'lighten-color' : ''} spinner-border`}
      role="status"
      style={{
        // color: '#CCC',
        height: customDimensions?.height ? customDimensions?.height : '100px',
        width: customDimensions?.width ? customDimensions?.width : '100px',
      }}
    ></div>
  );
};

export default LoadingSpinner;
