import React from 'react';

const checkFor = (name, errors) => errors && errors.find(e => e.name === name);

const checkError = ({ networkError, graphQLErrors }, children) => {
  // if (checkFor('AuthorizationError', graphQLErrors)) {
  //   // throw new Error('AuthorizationError');
  //   return <span>You are not authorized.</span>;
  // }
  // return children;
  // networkError is defined when the response is not a valid GraphQL response, e.g. the server is completely down
  //   if ( networkError ) {
  //     throw new ServerError();
  //   }
  //   if (checkFor('server_error', graphQLErrors)) {
  //     throw new ServerError();
  //   }
  //   if (checkFor('authorization_error', graphQLErrors)) {
  //     throw new AuthorizationError();
  //   }
  //   if (checkFor('resource_not_found', graphQLErrors)) {
  //     throw new ResourceNotFound();
  //   }
};

const GraphqlErrorHandler = ({ error, children }) => {
  // if (error) {
  //   return checkError(error, children);
  // }

  return children;
};

export default GraphqlErrorHandler;
