import React from 'react';
import WithLocation from '../../../components/WithLocation';

// https://github.com/reach/router/issues/242
export default WithLocation(({ selector, children, location, exceptions }) => {
  React.useEffect(() => {
    // const foundException =
    //   (exceptions && exceptions.find(route => route === location.pathname)) ||
    //   null;

    // if (!foundException) {
    //   document.querySelector(selector).scrollTo(0, 0);
    // }

    document.querySelector(selector).scrollTop = 0;
  }, [location.pathname]); // , [location.pathname]
  return children;
});
