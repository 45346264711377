import React, { Component, useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

export const AlertsContext = React.createContext();

const ALERTS_QUERY = gql`
  query {
    guestLeads(where: { status: OPEN }) {
      id
    }
    propertyVacancies {
      propertyId
    }
    propertyVacancyFragmentationReport {
      propertyId
    }
    propertyHVACLogAlerts {
      id
      type
      summary
      guestReservation {
        reference
        property {
          name
          streetAddress1
        }
        guest {
          id
          firstName
          lastName
          primaryPhoneNumber {
            phoneNumber
          }
        }
      }
      deviceName
      deviceLabel
      structureLabel

      property {
        name
        label
      }
    }
    airbnb3NightGapReport: dynamicReport(
      where: { slug: "/reports/airbnb-3-night-gap" }
    ) {
      count
    }
    unavailableDatesReport: dynamicReport(
      where: { slug: "/reports/unavailable-dates" }
    ) {
      count
    }
    bookingWindowsReport: dynamicReport(
      where: { slug: "/reports/booking-windows" }
    ) {
      count
    }
    propertyRateAlertsReport: dynamicReport(
      where: { slug: "/reports/property-rate-alerts" }
    ) {
      count
    }
    calendarEventAlerts {
      type
      summary
      level
      calendarEvent {
        id
        uid
        summary
        start
        end
        propertyListing {
          property {
            id
            label
            key
            name
          }
          platform {
            name
          }
        }
      }
    }
  }
`;
let cachedData = null;

const AlertsProvider = ({ children }) => {
  const [calendarAlertsState, setCalendarAlerts] = useState(null);
  // useEffect(() => {
  //   console.log('useEffect');
  // }, [calendarAlertsState]);

  return (
    <Query query={ALERTS_QUERY} pollInterval={30000}>
      {({ loading, error, data, refetch, startPolling, stopPolling }) => {
        // if (loading) return <>loading..</>;
        // if (error) return <p className="mt-2">{error.message}</p>;

        const calendarAlerts = data?.calendarEventAlerts || [];
        const calendarRedAlerts =
          (calendarAlerts || []).length > 0
            ? calendarAlerts.filter(alert => alert.level === 300)
            : [];

        // if (!cachedData) {
        //   setTimeout(() => {
        //     cachedData = cachedData && cachedData.slice(1);
        //     setCalendarAlerts(true);
        //   }, 20000);
        //   cachedData = calendarAlerts;
        // }

        return (
          <AlertsContext.Provider
            value={{
              state: {
                guestLeads: data ? data.guestLeads : [],
                propertyVacancies: data ? data.propertyVacancies : [],
                propertyVacancyFragmentationReport: data ? data.propertyVacancyFragmentationReport : [],
                calendarRedAlerts,
                airbnb3NightGapReport: data ? data.airbnb3NightGapReport : {},
                unavailableDatesReport: data ? data.unavailableDatesReport : {},
                bookingWindowsReport: data ? data.bookingWindowsReport : {},
                calendarEventAlerts: calendarAlerts,
                propertyHVACLogAlerts: data ? data.propertyHVACLogAlerts : [],
                propertyRateAlertsReport: data
                  ? data.propertyRateAlertsReport
                  : [],
                // calendarEventAlerts: cachedData,
              },
              refetch,
              loading,
              // setCalendarAlertsState: () => {
              //   setCalendarAlerts(true);
              // },
            }}
          >
            {children}
          </AlertsContext.Provider>
        );
      }}
    </Query>
  );
};

const AlertsConsumer = AlertsContext.Consumer;

export default AlertsProvider;
export { AlertsConsumer };
