import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.scss';
const Tooltip = ({ id, children, label, placement, className = '', hide }) => {
  // let tooltipRef;
  useEffect(() => {
    // ReactTooltip.rebuild();
  }, []);
  // useEffect(() => {
  //   $(tooltipRef).tooltip();
  //   return () => {
  //     $(tooltipRef).tooltip('dispose');
  //   };
  // }, []);

  return (
    // <span
    //   ref={el => (tooltipRef = el)}
    //   className={className}
    //   id={id}
    //   data-tooltip
    //   data-toggle="tooltip"
    //   data-placement={placement}
    //   title={label}
    // >
    //   {children}
    // </span>
    <>
      {hide ? (
        <span
          data-tip
          data-for={id}
          // ref={el => (tooltipRef = el)}
          className={className}
          id={id}
        >
          {children}
        </span>
      ) : (
        <>
          <span
            data-tip
            data-for={id}
            // ref={el => (tooltipRef = el)}
            className={className}
            id={id}
          >
            {children}
          </span>

          <ReactTooltip place={placement} id={id} type="dark" effect="solid">
            <span>{label}</span>
          </ReactTooltip>
        </>
      )}
    </>
  );
};

export default Tooltip;
