import React, { useRef, useEffect, useState } from 'react';
import NavMenu from './components/NavMenu';
import UserIdentity from './components/UserIdentity';
import '../styles/styles.scss';
import './styles.scss';
import ScrollToTop from './components/ScrollToTop';
// import styles from './styles.module.scss';
import { Link } from '@reach/router';
import Logo from '../images/favicon.png';
import WithLocation from '../components/WithLocation';
import UserProvider, { UserConsumer } from '../contexts/User';
import {
  CalendarProvider,
  CalendarConsumer,
} from '../routes/calendar/contexts/CalendarContext';
import { ArrowLeft, ArrowRight } from 'react-feather';
import debounce from 'src/util/debounce';
import Cookies from 'js-cookie';

import AlertsProvider, { AlertsConsumer } from '../contexts/Alerts';
import { Helmet } from 'react-helmet';
const NavbarWrapper = ({ isMenuCollapsed, role, location }) => {
  const locationState = location.state || {};
  const sideRailContainer = useRef(null);
  const [containerScrollPosition, setContainerScrollPosition] = useState(0);
  useEffect(() => {
    const getContainerScrollPosition = () => {
      const debounced = debounce(function() {
        setContainerScrollPosition(sideRailContainer.current.scrollTop);
      }, 200);
      debounced();
      // setContainerScrollPosition(sideRailContainer.current.scrollTop);
    };
    sideRailContainer.current?.scrollTo(0, locationState?.scrollPosition);

    $(sideRailContainer.current).on('scroll', getContainerScrollPosition);
    return () => {
      return () => {
        $(sideRailContainer.current).off('scroll', getContainerScrollPosition);
      };
    };
  }, [location.pathname]);
  return (
    <div ref={sideRailContainer} id="nav-parent">
      <NavMenu
        scrollPosition={containerScrollPosition}
        isMenuCollapsed={isMenuCollapsed}
        role={role}
      />
    </div>
  );
};

const Layout = ({
  children,
  metaTitle,
  location,
  mainCustomClasses,
  hideMainOverflow,
  hideNavbar = false
}) => {
  const menuToggleRef = useRef(null);
  const menuCanvasRef = useRef(null);

  const mainElement = useRef(null);

  const urlParams = new URLSearchParams(location.search);

  const isMenuCollapsedCookie =
    typeof window !== 'undefined' ? getCookie('menuCollapsed') : 'true';

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(
    isMenuCollapsedCookie === 'true' ? true : false
  );

  // const MainLayout = React.forwardRef((props, ref) => (
  //   <main
  //     ref={ref}
  //     className={`${urlParams.get('displaymode') &&
  //       'pb-1plus'} ${mainCustomClasses}`}
  //   >
  //     {children}
  //   </main>
  // ));
  // const mainLayoutRef = React.createRef();
  function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  const updateCollapsedMenu = () => {
    if (window.innerWidth < 992) {
      setIsMenuCollapsed(false);
    } else if (getCookie('menuCollapsed') === 'true') {
      setIsMenuCollapsed(true);
    }
  };

  useEffect(() => {
    // const isMenuCollapsedCookie = getCookie('menuCollapsed');
    /*
     There is a vertical scrollbar shown along the winow with scroll space the same size as the height of the horizontal scrollbar
     when the page first loads. If I toggle off and then back on the overflow of the 
     table wrapper element in the DOM inspector, the scrollbar goes away permanently thereafter.
     I suspect that the table loading (and not yet having a height that changes when the UI is loaded) 
     has to do with the window (main element) scrollbar showing up when it shouldn't
     As a fallback solution I am removing the overflow on the main element when the table loads because for this page it isn't being used
     */

    hideMainOverflow && $(mainElement?.current).css('overflow-y', 'hidden');

    if (isMenuCollapsedCookie === 'false') {
      setIsMenuCollapsed(false);
    } else if (isMenuCollapsedCookie === 'true') {
      setIsMenuCollapsed(true);
    }

    // if (isMenuCollapsedCookie === 'true' && !isMenuCollapsed) {
    //   setIsMenuCollapsed(true);
    // } else if (isMenuCollapsed) {
    //   setIsMenuCollapsed(true);
    // }
    /*
      Because the "pages", "posts", "properties", and "local-listings" pages
      all have the same '/pages' path, this component doesn't re-render, so the
      fly-out menu does not collapse. Whenever the location path changes, this function
      will cause the fly-out menu to collapse if it isn't already 
    */
    if (menuCanvasRef.current && menuToggleRef.current) {
      if (menuCanvasRef.current.classList.contains('open')) {
        menuCanvasRef.current.classList.toggle('open');
      }
      if (!menuToggleRef.current.classList.contains('collapsed')) {
        menuToggleRef.current.classList.toggle('collapsed');
      }
    }

    updateCollapsedMenu();

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [location.pathname]);

  const onWindowResize = () => {
    updateCollapsedMenu();
  };
  typeof window !== 'undefined' &&
    window.addEventListener('resize', onWindowResize);

  // const overflowClass =
  //   location.pathname === '/settings' ? 'overflow-auto' : '';

  return (
    <AlertsProvider>
      <UserProvider>
        <UserConsumer>
          {({ state: userState }) => {
            return (
              // <CalendarProvider>
              //   <CalendarConsumer>
              //     {({ state: calendarState }) => {

              //       return (
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>
                    {metaTitle ? `BHIP - ${metaTitle}` : 'BHIP Admin'}
                  </title>
                </Helmet>

               {!hideNavbar && <nav className={isMenuCollapsed ? 'collapsed' : ''}>
                  <header id="mobile-navbar">
                    <button
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      className="navbar-toggler collapsed ml-auto d-lg-none"
                      data-target="#navbarNav"
                      data-toggle="offcanvas"
                      type="button"
                      ref={menuToggleRef}
                      onClick={e => {
                        menuCanvasRef.current.classList.toggle('open');
                        menuToggleRef.current.classList.toggle('collapsed');
                        document
                          .querySelector('body')
                          .classList.toggle('offcanvas-open');
                      }}
                    >
                      <span className="icon-bar bar1"></span>
                      <span className="icon-bar bar2"></span>
                      <span className="icon-bar bar3"></span>
                    </button>
                    <div className="text-center mt-lg-3">
                      <Link to="/">
                        <div className="d-inline">
                          <img
                            className="logo"
                            src={Logo}
                            // height="48"
                            width={`${isMenuCollapsed ? '42' : '52'}`}
                          />
                          {!isMenuCollapsed && (
                            <strong
                              style={{ top: '2px' }}
                              className={`bhip-logo ml-2 position-relative`}
                            >
                              BHIP
                            </strong>
                          )}
                        </div>
                      </Link>
                    </div>
                  </header>
                  <div
                    ref={menuCanvasRef}
                    className="navbar-collapse offcanvas-collapse"
                    id="navbarNav"
                  >
                    <NavbarWrapper
                      location={location}
                      isMenuCollapsed={isMenuCollapsed}
                      role={userState?.userData?.role}
                    />
                    <div className="w-100 text-center">
                      <UserIdentity
                        isMenuCollapsed={isMenuCollapsed}
                        user={userState.userData}
                      ></UserIdentity>
                    </div>
                    <div
                      className="w-100 float-right siderail-collapse-button-wrapper"
                      onClick={() => {
                        setIsMenuCollapsed(collapsed => {
                          document.cookie = collapsed
                            ? 'menuCollapsed=false'
                            : 'menuCollapsed=true';
                          return !collapsed;
                        });

                        if (window.calendar) {
                          window.calendar.refetchEvents();
                          window.calendar.refetchResources();
                          window.calendar.destroy();
                          window.calendar.render();
                          window.calendar.updateSize();
                        }
                      }}
                    >
                      <button className="btn btn-link float-right d-none d-lg-block">
                        {isMenuCollapsed ? <ArrowRight /> : <ArrowLeft />}
                      </button>
                    </div>
                  </div>
                </nav>}
                <ScrollToTop selector="main" /*exceptions={['/calendar']}*/>
                  {/* <MainLayout ref={mainLayoutRef}>{children}</MainLayout> */}
                  <main
                    ref={mainElement}
                    className={`${urlParams.get('displaymode') &&
                      'pb-1plus'} ${mainCustomClasses}`}
                  >
                    {children}
                  </main>
                </ScrollToTop>
              </>
              //       );
              //     }}
              //   </CalendarConsumer>
              // </CalendarProvider>
            );
          }}
        </UserConsumer>
      </UserProvider>
    </AlertsProvider>
  );
};

Layout.defaultProps = {
  onLayoutResize: () => {},
};

export default WithLocation(Layout);
