import React from 'react';
import { Query } from 'react-apollo';
import ErrorHandler from './ErrorHandler';

export default ({ children, ...rest }) => (
  <Query /*errorPolicy="all"*/ {...rest}>
    {result => {
      return (
        <ErrorHandler
          // error={{
          //   graphQLErrors: [{ name: 'AuthorizationError' }],
          // }}
          error={result.error}
        >
          {children(result)}
        </ErrorHandler>
      );
    }}
  </Query>
);
