// import { createContext } from "react"

// export default createContext([{}, () => {}])

import React, { Component, useState, useEffect } from 'react';

// import { getTemplate } from '../../util/';

const CalendarContext = React.createContext();
const { Provider, Consumer } = CalendarContext;

const CalendarProvider = ({ value, children }) => {
  const [calendar, setCalendarState] = useState(null);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  return (
    <Provider
      value={{
        state: {
          calendar,
          isMenuCollapsed,
        },
        setCalendarState: calendar => setCalendarState(calendar),
        setIsMenuCollapsed: isCollapsed => setIsMenuCollapsed(!isCollapsed),
      }}
    >
      {children}
    </Provider>
  );
};

export { CalendarProvider, Consumer as CalendarConsumer };
export default CalendarContext;
