import React, { useState } from 'react';
import ThemedButton from '../../../components/Forms/components/ThemedButton';
import Cookies from 'js-cookie';
import { LogIn, LogOut, Home } from 'react-feather';
import './styles.scss';
import { Link } from '@reach/router';
import Tooltip from '../../../components/Tooltip';
const getGuestDisplay = ip => {
  if (ip) {
    const split = ip.split('.');
    if (split) {
      return `Guest ${split[split.length - 1]}`;
    }
  }
  return 'Guest';
};

const UserIdentity = ({ user, isMenuCollapsed }) => {
  const getDisplayByLoggedInStatus = () => {
    switch ((user || {}).role) {
      // case 'INHOUSE':
      //   return (
      //     <div className="p-3 smallest">
      //       Logged as{' '}
      //       {user.userName ? user.userName : getGuestDisplay(user.ipAddress)}
      //     </div>
      //   );
      case 'AUTHENTICATED':
        return (
          // <>
          //   <div className="p-3 smallest">
          //     <div>
          //       Logged as{' '}
          //       {user.userName
          //         ? user.userName
          //         : getGuestDisplay(user.ipAddress)}
          //     </div>
          //     <a
          //       href="#"
          //       title="Log Out"
          //       onClick={() => {
          //         Cookies.remove('token');
          //         window.location = '/';
          //       }}
          //       className={`${isMenuCollapsed ? 'collapsed' : ''} pt-1 d-block`}
          //     >
          //       {isMenuCollapsed ? (
          //         <LogOut />
          //       ) : (
          //         <span className="w-75 smallest">Log Out</span>
          //       )}
          //     </a>
          //   </div>
          // </>
          <>
            {!isMenuCollapsed && (
              <div className="pt-3 smallest">
                Logged as{' '}
                {user.userName
                  ? user.userName
                  : getGuestDisplay(user.ipAddress)}
              </div>
            )}
            <Tooltip
              label={'Log Out'}
              placement="right"
              id={`logout-tooltip`}
              hide={!isMenuCollapsed}
              // className="d-none"
            >
              <a
                href="#"
                // title="Log Out"
                onClick={() => {
                  Cookies.remove('token');
                  window.location = '/';
                }}
                // className={`${isMenuCollapsed ? 'collapsed' : ''} pt-1 d-block`}
                className={`login-link nav-link ${
                  isMenuCollapsed ? 'collapsed' : ''
                }`}
              >
                {isMenuCollapsed ? (
                  <LogOut size="15" />
                ) : (
                  <span className="w-75 smallest">Log Out</span>
                )}
              </a>
            </Tooltip>
          </>
        );
      default:
        return (
          // <div
          //   className={`login-link ${
          //     isMenuCollapsed ? 'collapsed my-3 py-2' : 'p-3'
          //   }`}
          // >
          <Tooltip
            label={'Login'}
            placement="right"
            id={`login-tooltip`}
            hide={!isMenuCollapsed}
            // className="d-none"
          >
            <a
              // className={`pt-3 pb-3`}
              className={`login-link nav-link ${
                isMenuCollapsed ? 'collapsed' : ''
              }`}
              // title="Log In"
              href="/login"
            >
              {isMenuCollapsed ? (
                <LogIn size="15" />
              ) : (
                <ThemedButton className="w-75 m-3 login-button btn btn-outline-primary">
                  Login
                </ThemedButton>
              )}
            </a>
          </Tooltip>
          // <Link>
          //   <LogIn size="15" />
          // </Link>
          // </div>
        );
    }
  };
  return getDisplayByLoggedInStatus();
};

export default UserIdentity;
